<script setup lang="ts">
import type { FormattedCity } from '@/types/api/cities'
import type { Geoobject } from '@/types/main'

type Props = {
  modelValue: string
  isInputFocused: boolean
  formattedCitiesList: FormattedCity[]
}

type Emits = {
  (fn: 'update:model-value', value: string): void
  (fn: 'changeFocus', state: boolean): void
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
})

const emit = defineEmits<Emits>()

const { isMobile } = useResponsive()

const geoData = ref<null | Geoobject>(null)

const searchValue = computed(() => {
  if (props.isInputFocused || props.modelValue || !geoData.value) {
    return props.modelValue
  }

  for (const city of props.formattedCitiesList) {
    const cityName = city.visible_name.toLowerCase()
    // Ищем соответсвие между городом из списка и гором клиента
    if (isGeoEqualCurrentCityName(cityName)) {
      return city.visible_name
    }
  }

  return props.modelValue
})

const cityDomainPrefix = computed(() => {
  return 'ufa' // заглушка, чтобы сделать запрос на получение адреса
})

const cityUrl = computed(() => {
  if (!geoData.value) {
    return null
  }

  let url: string | null = null

  for (const city of props.formattedCitiesList) {
    const cityName = city.visible_name.toLowerCase()

    // Ищем соответсвие между городом из списка и городом клиента
    if (isGeoEqualCurrentCityName(cityName)) {
      url = `https://${city.domain}`
    }
  }

  return url
})

const isShowmenuBtn = computed(() =>
  Boolean(geoData.value && geoData.value.address?.province && cityUrl.value),
)

onMounted(() => {
  setAriaField()
  setGeolocation()
})

const isGeoEqualCurrentCityName = (cityName: string) => {
  const province = geoData.value?.address?.province.toLowerCase()
  const locality = geoData.value?.address?.locality.toLowerCase()

  return (
    cityName === province ||
    cityName === locality ||
    locality?.includes(cityName)
  )
}

const setAriaField = () => {
  let field = document.querySelector('.v-field')

  field?.setAttribute('aria-label', 'Начните вводить название города')

  field = null
}

const setGeolocation = async () => {
  try {
    const { coords } = await getMyPosition()
    const { latitude, longitude } = coords
    const { $axios } = useNuxtApp()

    const geocodeService = getGeocodeService(
      { axios: $axios },
      cityDomainPrefix.value,
    )

    geoData.value = await geocodeService.geocode([latitude, longitude])
  } catch (error) {
    console.log('setGeolocation. Ошибка при определении геопозиции', error)
  }
}

const emitSearchQuery = (value: string) => {
  emit('update:model-value', value)
}

const changeFocusCityInput = (isFocused: boolean) => {
  emit('changeFocus', isFocused)
}

const onClickOtherCity = () => {
  const searchInput = document.querySelector('#searchInput input')

  if (searchInput instanceof HTMLElement) {
    searchInput.focus()
    changeFocusCityInput(true)
  }
}
</script>

<template>
  <div class="select-city">
    <h2 class="select-city__title">Перейти на сайт города</h2>

    <div
      id="searchInput"
      class="select-city__search-city"
      @click="changeFocusCityInput(true)"
    >
      <the-input
        :model-value="searchValue"
        class="select-city__search-input"
        placeholder="Введите название города"
        aria-disabled="true"
        aria-labelledby="input-1"
        hide-details
        :clearable="false"
        @update:model-value="emitSearchQuery"
      />
    </div>

    <div class="select-city__btns">
      <div v-if="isShowmenuBtn" class="select-city__menu-btn">
        <a :href="cityUrl || ''" target="_blank">
          <the-button @click="onClickOtherCity">
            Перейти к меню
            <template #append>
              <the-icon name="arrow-right" size="32" color="var(--ui-white)" />
            </template>
          </the-button>
        </a>
      </div>

      <div id="btnOtherCity" class="select-city__other-city-btn">
        <the-button
          :variant="isMobile ? 'secondary' : 'tertiary'"
          @click="onClickOtherCity"
        >
          Другой город
        </the-button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.cities-block--active-search {
  .select-city {
    width: max-content;
    margin-bottom: 0;

    &__title {
      height: 0;
      margin-bottom: 0;
      overflow: hidden;
      opacity: 0;
    }

    &__btns {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
    }
  }

  @include media('<tablet') {
    .select-city {
      width: 100%;

      &__search-city {
        margin-bottom: 20px;
      }
    }
  }
}

.select-city {
  &__title {
    color: var(--ui-color-white);
    max-width: 478px;
    height: 94px;
    margin-bottom: 30px;
    font-size: 40px;
    font-weight: 800;
    line-height: 47px;
    transition: 0.3s;
  }

  &__search-city {
    margin-bottom: 40px;
  }

  &__search-input {
    max-width: 501px;

    :deep(.app-input__control) {
      background-color: transparent;
      border: none;
      border-radius: unset;
      padding: 0;
      border-bottom: 4px solid var(--ui-color-primary);

      &:hover {
        background-color: transparent;
      }
    }

    :deep(.app-input__field) {
      color: var(--ui-color-white);
      font-size: 26px;
      font-weight: 400;
      padding: 9px 0;
      line-height: unset;

      &::placeholder {
        font-weight: 400;
        font-size: 26px;
        color: var(--ui-color-sand-200);
        line-height: unset;
      }

      @include media('<tablet') {
        font-size: 24px;

        &::placeholder {
          font-size: 24px;
        }
      }
    }
  }

  &__btns {
    display: flex;
    gap: 30px;
    height: 50px;
    transition: 0.3s;

    .select-city__btn {
      border-radius: 10px !important;
    }

    .app-button {
      width: 100%;
    }
  }

  &__menu-btn {
    width: 310px;
  }

  &__btn-container {
    display: flex;
    gap: 23px;
    align-items: center;
    border-radius: 10px !important;
  }

  &__icon-arrow {
    width: 27px;
    height: 27px;
  }

  &__other-city-btn {
    width: max-content;
  }

  @include media('<desktop') {
    margin-bottom: 80px;
  }

  @include media('<tablet') {
    margin-bottom: 20px;

    &__title {
      color: var(--ui-color-white);
      max-width: unset;
      height: 28px;
      font-size: 24px;
      line-height: 28px;
    }

    &__search-input {
      width: 100%;
      max-width: unset;
    }

    &__btns {
      flex-direction: column;
      gap: 20px;
      height: max-content;
      max-height: 120px;
    }

    &__menu-btn,
    &__other-city-btn {
      width: 100%;
    }

    &__icon-arrow {
      width: 18px;
      height: 18px;
    }
  }
}
</style>
