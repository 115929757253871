<script setup lang="ts">
import imageStoreBadge from '@/assets/images/app-store-badge.svg'
import imageGoogleBadge from '@/assets/images/app-google-badge.svg'

import FooterLinksDownloadButton from '@/components/Footer/FooterLinksDownloadButton.vue'

/* Types */
type Links = {
  id: number
  href: string
  icon: string
  alt: string
  meta: object
  title: string
}

const { isMobile } = useResponsive()

const links = [
  {
    id: 1,
    href: ' https://apps.apple.com/ru/app/id1158898682?referrer=appmetrica_tracking_id%3D818922990581394302%26ym_tracking_id%3D12865574045399502275',
    meta: {
      event: 'appstore-click',
    },
    icon: imageStoreBadge,
    alt: 'AppStore',
    title: 'Скачать приложение Farfor в AppStore',
  },
  {
    id: 2,
    href: ' https://play.google.com/store/apps/details?id=ru.farfor&referrer=appmetrica_tracking_id%3D170404690310291845%26ym_tracking_id%3D6050769962065580548',
    meta: {
      event: 'googleplay-click',
    },
    icon: imageGoogleBadge,
    alt: 'GooglePlay',
    title: 'Скачать приложение Farfor в GooglePlay',
  },
] as Links[]

const gtm = useGtm()

const onClickLink = (link: Links) => {
  if (!gtm) {
    return
  }

  gtm.trackEvent(link.meta)
}
</script>

<template>
  <div class="download-application">
    <template v-if="!isMobile">
      <div class="download-application__text-block">
        <h2 class="download-application__title">
          Скачать
          <br />
          приложение
        </h2>

        <div class="download-application__text">
          Просто наведите камеру телефона
          <br />
          на QR-код и перейдите по ссылке
        </div>

        <div class="download-application__links">
          <the-button
            v-for="link in links"
            :key="link.id"
            :href="link.href"
            :title="link.title"
            class="download-application__link"
            rel="nofollow"
            target="_blank"
            variant="transparent"
            @click="onClickLink(link)"
          >
            <img width="128" height="40" :src="link.icon" :alt="link.alt" />
          </the-button>
        </div>
      </div>

      <div class="download-application__qr-code-container">
        <div class="download-application__qr-code" />
        <div class="border-lt border"></div>
        <div class="border-rt border"></div>
        <div class="border-rb border"></div>
        <div class="border-lb border"></div>
      </div>
    </template>

    <div v-else class="download-application__download-button">
      <footer-links-download-button />
    </div>
  </div>
</template>

<style scoped lang="scss">
.download-application {
  display: flex;
  gap: 91px;
  height: max-content;
  color: var(--ui-color-white);

  &__title {
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: 800;
    line-height: 47px;
  }

  &__text {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  &__links {
    display: flex;
    gap: 10px;
    margin-top: 30px;

    .app-button {
      padding: 0;
      min-height: unset;
      height: max-content;
      border-radius: unset;
      background-color: transparent !important;
    }
  }

  &__link {
    width: 128px;
  }

  &__qr-code {
    width: 160px;
    height: 160px;
    background-image: url('/assets/images/app-qr.webp');
    background-position: center;
    border-style: none !important;
  }

  &__qr-code-container {
    position: relative;
    align-self: center;
    width: 200px;
    height: 200px;
    padding: 20px;

    img {
      width: 100%;
    }

    .border {
      position: absolute;
      width: 53px;
      height: 53px;
      background-image: url('/assets/images/qr-code-border.png');
      border-style: none !important;
    }

    .border-lt {
      top: 0;
      left: 0;
    }

    .border-rt {
      top: 0;
      right: 0;
      transform: rotate(90deg);
    }

    .border-rb {
      right: 0;
      bottom: 0;
      transform: rotate(180deg);
    }

    .border-lb {
      bottom: 0;
      left: 0;
      transform: rotate(270deg);
    }
  }

  &__download-button {
    width: 100%;
  }
}
</style>
