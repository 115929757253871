<script setup lang="ts">
import CyrillicToTranslit from 'cyrillic-to-translit-js'
import ruLayoutConvert from 'convert-layout'

/* Stores */
import { useCitiesStore } from '@/store/cities/cities'

/* Components */
import DownloadApp from '@/components/Main/CitiesBlock/DownloadApp.vue'
import SelectCity from '@/components/Main/CitiesBlock/SelectCity.vue'
import CitiesListComponent from '@/components/Main/CitiesBlock/CitiesList.vue'

/* Types */
import type { City, FormattedCity } from '@/types/api/cities'
import { DOMAIN_COUNTRY } from '@/types/common'

/* Assets */
import videoBackground from '@/assets/video/video.webm'

const citiesStore = useCitiesStore()

const { isMobile } = useResponsive()

const searchQuery = ref('')
const isInputCityFocused = ref(false)

const domainCountry = computed(() => {
  let domain = location.hostname.split('.').slice(-1).join('.')

  if (!(domain in DOMAIN_COUNTRY)) {
    domain = 'ru'
  }
  return domain
})

const citiesList = computed(() => citiesStore.citiesList || [])

const formattedCitiesList = computed<FormattedCity[]>(() => {
  const formattedCitiesList: FormattedCity[] = []

  for (const city of citiesList.value) {
    // Показываем города текущей страны
    if (
      city?.country?.name ===
      DOMAIN_COUNTRY[domainCountry.value as keyof typeof DOMAIN_COUNTRY]
    ) {
      formattedCitiesList.push({
        ...city,
        cityUrl: `https://${city.domain}`,
      })
    }
  }

  // возвращаем полный список с добавлением страны и первой буквы города
  if (!searchQuery.value) {
    return setCountryAndLetter(formattedCitiesList)
  }
  // возвращаем отфильтрованный список с добавлением первой буквы города
  return setCountryAndLetter(filterList(formattedCitiesList))
})

onMounted(() => {
  if (!citiesList.value.length) {
    citiesStore.fetchCities()
  }

  document.addEventListener('click', cityInputBlur)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', cityInputBlur)
})

const setCountryAndLetter = (citiesList: City[]) => {
  const parameters: { [key: string]: Array<string> } = {}

  return citiesList.map((city: City) => {
    const firstCountry: string = city.country.name
    const firstLetter: string = city.visible_name[0]

    if (Object.prototype.hasOwnProperty.call(parameters, firstCountry)) {
      if (parameters[firstCountry].includes(firstLetter)) {
        return city
      }

      parameters[firstCountry].push(firstLetter)
      return { ...city, firstLetter }
    }

    parameters[firstCountry] = [firstLetter]

    return { ...city, firstLetter, firstCountry }
  })
}

const filterList = (citiesList: City[]) => {
  const searchQueryString: string = searchQuery.value
    ? searchQuery.value.toLowerCase().trim()
    : ''

  return citiesList.filter((city: City) => {
    const name: string = city.visible_name.toLowerCase()

    return (
      name.includes(searchQueryString) ||
      // в случае случайной установки раскладки на английский язык
      name.includes(ruLayoutConvert.ru.fromEn(searchQueryString)) ||
      // в случае написания транслитом
      CyrillicToTranslit().transform(name).includes(searchQueryString)
    )
  })
}

const changeFocusCityInput = (isFocused: boolean) => {
  isInputCityFocused.value = isFocused
}

const cityInputBlur = (event: Event) => {
  const searchInput = document.querySelector('#searchInput')
  const citiesList = document.querySelector('#citiesList')
  const buttonOtherCity = document.querySelector('#btnOtherCity')
  const target = event.target as Node

  if (
    searchQuery.value.length ||
    !isInputCityFocused.value ||
    citiesList?.contains(target) ||
    searchInput?.contains(target) ||
    buttonOtherCity?.contains(target)
  ) {
    return
  }

  isInputCityFocused.value = false
}
</script>

<template>
  <div
    class="cities-block"
    :class="{ 'cities-block--active-search': isInputCityFocused }"
  >
    <div v-if="!isMobile" class="cities-block__video">
      <transition name="video">
        <video v-show="!isInputCityFocused" autoplay muted loop>
          <source :src="videoBackground" type="video/mp4" />
        </video>
      </transition>

      <div class="cities-block__video-background"></div>
    </div>

    <div class="cities-block__content">
      <select-city
        v-model="searchQuery"
        :is-input-focused="isInputCityFocused"
        :formatted-cities-list="formattedCitiesList"
        @change-focus="changeFocusCityInput"
      />
      <download-app class="cities-block__download-application" />
    </div>

    <cities-list-component
      :active-search="isInputCityFocused"
      :search-query="searchQuery"
      :formatted-cities-list="formattedCitiesList"
    />
  </div>
</template>

<style scoped lang="scss">
.cities-block {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 75px;
  margin-bottom: 90px;

  &__video {
    position: absolute;
    left: calc(((100vw - 1200px) / -2)); // отступ слева учитывая width layout'a
    width: 100vw;
    height: 100%;
    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .video-enter-active,
    .video-leave-active {
      transition: all 0.3s ease;
    }

    .video-enter-from,
    .video-leave-to {
      overflow: hidden;
      opacity: 0 !important;
    }

    .video-enter-to {
      overflow: hidden;
      opacity: 1 !important;
    }
  }

  &__video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(25, 26, 30, 0.6);
  }

  &__content {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-top: 224px; // 90px - хедер + 134px - отступ
  }

  &__download-application {
    height: 224px;
    overflow: hidden;
    transition: 0.3s;
  }

  &--active-search {
    justify-content: flex-start;
    padding-bottom: 0;

    .cities-block__content {
      flex: unset;
    }

    .cities-block__download-application {
      height: 0;
      opacity: 0;
    }
  }

  @include media('<desktop') {
    margin-bottom: 80px;

    &__video {
      left: calc(((100vw - 1000px) / -2));
    }

    &__content {
      flex-direction: column;
    }
  }

  @include media('<desktop-mini') {
    padding: 0 48px 0 48px;
    margin-bottom: 40px;

    &__video {
      left: 0;
    }

    &__content {
      padding-top: 182px; // 121px - высота хедера + 61px - отсутп
    }
  }

  @include media('<tablet') {
    min-height: unset;
    padding: 0 20px 148px 20px;

    &__content {
      padding-top: 60px; // 60px - отсутп
    }

    &__download-application {
      height: 56px;
    }
  }
}
</style>
