<script setup lang="ts">
/* Components */
import NewsCard from '@/components/News/NewsCard.vue'
import StatisticCard from '@/components/News/StatisticCard.vue'

/* Types */
import type { SlugNews } from '@/types/api/news'
import type { DailyStatistic } from '@/types/dailyStatistics'
import { Success } from '@/types/api/results'

const { $apiService } = useNuxtApp()
const { isMobile } = useResponsive()

onMounted(() => {
  fetchNewsData()
  fetchDailyStatistics()
})

const newsData = ref<SlugNews[]>([])

const dailyStatisticsData = ref<DailyStatistic[]>([
  {
    value: 0,
    splitted_value: [0],
    unit: '',
    title: '',
  },
  {
    value: 0,
    splitted_value: [0],
    unit: '',
    title: '',
  },
  {
    value: 0,
    splitted_value: [0],
    unit: 'мин',
    title: 'Среднее <br/> время доставки',
  },
  {
    value: 0,
    splitted_value: [0],
    unit: '',
    title: 'Заказов выполнено',
  },
  {
    value: 0,
    splitted_value: [0],
    unit: 'км',
    title: 'Проехали наши курьеры',
  },
  {
    value: 0,
    splitted_value: [0],
    unit: '',
    title: 'Человек <br/> мы накормили',
  },
  {
    value: 0,
    splitted_value: [0],
    unit: '',
    title: '',
  },
  {
    value: 0,
    splitted_value: [0],
    unit: 'млн',
    title: 'Заказов за всё время',
  },
])

const isFetchDailyStatisticsError = ref(false)

const newsList = computed(() => {
  const list: SlugNews[] = []

  for (const [index, news] of newsData.value.entries()) {
    if (index > 4) {
      break
    }

    list.push(news)
  }

  return list
})

const dailyStatisticsList = computed(() => {
  const list: DailyStatistic[] = []

  for (const [index, statistic] of dailyStatisticsData.value.entries()) {
    const defaultStatistic = { ...statistic }

    switch (index) {
      case 0:
        defaultStatistic.unit = 'городов'
        defaultStatistic.title = ''

        break

      case 1:
        defaultStatistic.unit = 'кухонь'
        defaultStatistic.title = ''
        break

      case 2:
        defaultStatistic.unit = 'минут'
        defaultStatistic.title = 'Среднее время доставки'
        break

      case 3:
        defaultStatistic.unit = 'заказов'
        defaultStatistic.title = 'За сегодня приготовили'
        break

      case 4:
        defaultStatistic.unit = 'км'
        defaultStatistic.title = 'Наши курьеры проехали'
        break

      case 6:
        defaultStatistic.unit = 'сотрудников'
        defaultStatistic.title = 'Для вас стараются'
        break

      case 5:
      case 7:
        continue
    }

    list.push(defaultStatistic)
  }

  return list
})

const fetchDailyStatistics = async () => {
  const responseDailyStatistics =
    await $apiService.common.fetchDailyStatistics()

  if (
    responseDailyStatistics instanceof Success &&
    responseDailyStatistics.data?.statistics
  ) {
    dailyStatisticsData.value = responseDailyStatistics.data.statistics
  } else {
    isFetchDailyStatisticsError.value = true
  }
}

const fetchNewsData = async () => {
  const responseNewsData = await $apiService.common.fetchListNews(1, 6)

  if (responseNewsData instanceof Success) {
    newsData.value = responseNewsData.data?.results || []
  }
}
</script>

<template>
  <div class="news-block">
    <div id="newsBlock" class="news">
      <h2 class="news-block__title">Новости</h2>

      <div class="news-list">
        <div class="news-list__column">
          <nuxt-link
            v-for="news in newsList.slice(0, 3)"
            :key="news.slug"
            class="news-list__link"
            :to="`/news/${news.slug}/`"
          >
            <news-card :news="news" />
          </nuxt-link>
        </div>
        <div class="news-list__column">
          <div v-if="!isMobile" class="news-list__column--2">
            <nuxt-link
              v-for="news in newsList.slice(3, 5)"
              :key="news.slug"
              :to="`/news/${news.slug}/`"
              :title="news.title"
              class="news-list__link"
            >
              <news-card :news="news" />
            </nuxt-link>
          </div>

          <the-button title="Новости" to="/news/" variant="secondary" block>
            Еще новости
          </the-button>
        </div>
      </div>
    </div>

    <div
      v-if="!isFetchDailyStatisticsError"
      id="dailyStatistics"
      class="daily-statistics"
    >
      <h2 class="news-block__title">Цифры дня</h2>
      <div class="daily-statistics__list">
        <statistic-card
          v-for="(statistic, index) in dailyStatisticsList"
          :key="`st_${index}`"
          class="daily-statistics__statisctic"
          :class="`v-statisctic--${index}`"
          :statistic="statistic"
          :index="index"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.news-block {
  display: flex;
  justify-content: space-between;
  padding-bottom: 140px;

  &__title {
    margin-bottom: 40px;
    font-size: 40px;
    font-weight: 800;
    color: var(--ui-color-white);
  }

  @include media('<desktop-mini') {
    flex-direction: column-reverse;
    padding: 0 48px 139px 48px;
  }

  @include media('<tablet') {
    flex-direction: column;
    padding: 0 20px 80px 20px;

    &__title {
      font-size: 30px;
    }
  }
}

.news-list {
  display: flex;
  gap: 17px;

  &__link {
    height: max-content;
    transition: 0.2s ease-in;

    &:hover {
      cursor: pointer;
      transform: translateY(5px);
    }
  }

  &__column,
  &__column--2 {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  @include media('>=desktop-mini', '<desktop') {
    flex-direction: column;
    max-width: 282px;

    &__column--2 {
      display: none;
    }
  }

  @include media('<tablet') {
    flex-direction: column;
    padding-bottom: 80px;
  }
}

.daily-statistics {
  &__list {
    position: relative;
    width: 463px;
    height: 532px;
  }

  &__statisctic {
    position: absolute;
  }

  .v-statisctic--0 {
    top: 30px;
    // городов
    left: 0;
  }

  .v-statisctic--1 {
    top: 75px;
    // кухонь
    left: 188px;
  }

  .v-statisctic--2 {
    top: 461px;
    // среднее время доставки
    left: 49px;
  }

  .v-statisctic--3 {
    top: 239px;
    // сегодня приготовили
    left: 288px;
  }

  .v-statisctic--4 {
    top: 256px;
    // курьеры км проехали
    left: 18px;
  }

  .v-statisctic--5 {
    top: 412px;
    // сотрудников
    left: 278px;
  }

  @include media('>=tablet', '<desktop') {
    padding-bottom: 142px;
  }

  @include media('<tablet') {
    &__list {
      position: relative;
      width: calc(100% - 17px);
      height: 720px;
      margin-left: 17px;
      overflow: hidden;
    }

    .v-statisctic--0 {
      top: 24px;
      // городов
      left: 1px;
    }

    .v-statisctic--1 {
      top: 133px;
      right: 64px;
      // кухонь
      left: inherit;
    }

    .v-statisctic--2 {
      top: 650px;
      right: 86px;
      // среднее время доставки
      left: inherit;
    }

    .v-statisctic--3 {
      // сегодня приготовили
      top: 380px;
      right: 30px;
      left: inherit;
    }

    .v-statisctic--4 {
      // курьеры км проехали
      top: 270px;
      left: 12px;
    }

    .v-statisctic--5 {
      top: 515px;
      // сотрудников
      left: 1px;
    }
  }
}
</style>
